import { useContext, useState } from "react"
import { UserContext } from "../context"

const Form = (props) => {
    const context = useContext(UserContext)
    const [visible, setVisible] = useState(false)
    const fielder = (fields) => {
        return fields.map((item, i) =>
        (<div className="field" key={i}>
            <label className="label">{item.label}</label>
            <div className="control has-icons-left has-icons-right">
                <input
                    className="input "
                    name={item.name}
                    type={!item.eye ? item.type : visible ? 'text' : 'password'}
                    placeholder={item.placeholder}
                    value={props.formState[item.name]}
                    onChange={item.onChange}
                    autoComplete={item.autoComplete}
                    onKeyDown={(e) => { if (e.code === 'Enter' && i === fields.length - 1) { props.handleSubmit(e) } }}
                />
                <span className={"icon is-small is-left"}>
                    <i className={"fas fa-" + item.icon}></i>
                </span>

                {item.eye ?
                    <span style={{ zIndex: 4 }} className={"icon is-small is-right is-clickable"} onClick={(e) => setVisible(!visible)} >
                        <i className="fas fa-eye"></i>
                    </span> :
                    null}
            </div>
            <div className={"help is-danger " + (props.formMessage[item.name] === 'danger' ? '' : 'is-hidden')}>{item.danger}</div>
            <div className={"help is-success " + (props.formMessage[item.name] === 'success' ? '' : 'is-hidden')}>{item.success}</div>
            <div className={"help " + (props.formMessage[item.name] === 'warning' ? '' : 'is-hidden')}>{item.warning}</div>
        </div>)
        )
    }

    return (
        <form className={'box ' + context.theme.backgroundLight} onSubmit={props.handleSubmit}>
            {fielder(props.fields)}
            <div className="field is-grouped">
                <div className="control">
                    <button
                        type='button'
                        className={"button is-success " + (props.button.loading ? 'is-loading' : '')}
                        onClick={props.handleSubmit} >
                        {props.button.content}
                    </button>
                </div>
                <div className="control">
                    <button
                        type='button'
                        className="button is-success is-light"
                        onClick={(e) => props.setActive({...props.active, navModal: ''})}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Form