import { useContext, useEffect, useState } from "react"
import { UserContext } from '../context.js'
import Form from "./Form.js"
const SignUpForm = (props) => {
    const context = useContext(UserContext)
    const [button, setButton] = useState({
        loading: false,
        content: 'Submit'
    })
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        password: '',
        passwordCheck: ''
    })
    const [testState, setTestState] = useState({
        minLength: false,
        maxLength: false,
        letter: false,
        number: false,
        character: false
    })
    const [formMessage, setFormMessage] = useState({
        name: '',
        email: '',
        password: 'warning',
        passwordCheck: ''
    })

    let base = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3001/'
    : 'https://cannafindalicense.herokuapp.com/';


    useEffect(() => {
        const passwordTest = (string) => {
            let result = {
                minLength: false,
                maxLength: false,
                letter: false,
                number: false,
                special: false
            }
            let letterReg = new RegExp('^(?=.{2,}$)(?=.*[a-z])(?=.*[A-Z]).*$')
            let numberReg = new RegExp('([\\d])')
            let specialReg = new RegExp('([!@#.$%^&*])')
            if (string.length >= 8) {
                result.minLength = true
            }
            if (string.length <= 100) {
                result.maxLength = true
            }
            if (letterReg.test(string)) {
                result.letter = true
            }
            if (numberReg.test(string)) {
                result.number = true
            }
            if (specialReg.test(string)) {
                result.special = true
            }
            return result
        }
        setTestState(passwordTest(formState.password))
    }, [formState])


    useEffect(() => {
        let bool = Object.values(testState).every(item => item)
        if (bool) {
            setTimeout(() => {
                setFormMessage({
                    ...formMessage,
                    password: 'success'
                })
            }, 670)
        } else {
            setFormMessage({
                ...formMessage,
                password: 'warning'
            })
        }
        if (formState.password === formState.passwordCheck) {
            setFormMessage({ ...formMessage, passwordCheck: '' })
        }
    }, [testState, formState])


    const handleChange = (e) => {
        let { name, value } = e.target
        setFormMessage({ ...formMessage, [name]: '' })
        setFormState({
            ...formState,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        if (formState.name === '') {
            setFormMessage({ ...formMessage, name: 'warning' })
            return
        }
        if (formState.email === '') {
            setFormMessage({ ...formMessage, email: 'warning' })
            return
        }
        if (formState.password === '') {
            setFormMessage({ ...formMessage, password: 'danger' })
        }
        if (formState.password !== formState.passwordCheck) {
            setFormMessage({ ...formMessage, passwordCheck: 'danger' })
            return
        }
        if (!Object.values(testState).every(item => item)) {
            setFormMessage({ ...formMessage, password: 'warning' })
            return
        }
        setButton({
            ...button,
            loading: true
        })
        let body = {
            name: formState.name.toLocaleLowerCase(),
            email: formState.email.toLocaleLowerCase(),
            password: formState.password
        }
        let bool = Object.values(body).every(value => value !== '')
        if (bool) {
            fetch(base + 'api/user/signUp', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(body)
            }).then(response => response.json())
                .then(data => {
                    if (data.user === 'Email already in use') {
                        setFormMessage({
                            ...formMessage,
                            email: 'danger'
                        })
                        setButton({
                            ...button,
                            loading: false
                        })
                        return
                    }
                    setButton({ content: 'Success', loading: false })
                    setTimeout(() => {
                        context.login(data.user)
                        setFormState({ name: '', email: '', password: '', passwordCheck: '' })
                        props.setActive({...props.active, navModal: ''})
                    }, 1000)
                }).catch(err => console.log(err))
        } else {
            for (let prop in body) {
                if (body[prop] === '') {
                    setFormMessage({ ...formMessage, })
                }
            }
        }

    }

    return (
        <Form
            formState={formState}
            setFormState={setFormState}
            formMessage={formMessage}
            setFormMessage={setFormMessage}
            handleSubmit={handleSubmit}
            setActive={props.setActive}
            active={props.active}
            button={button}
            fields={[
                {
                    label: 'Name',
                    name: 'name',
                    type: 'name',
                    icon: 'user',
                    warning: 'Must include a name. (can be made up, its just for display)',
                    placeholder: 'Please enter your name',
                    onChange: handleChange
                },
                {
                    label: 'Email',
                    name: 'email',
                    type: 'email',
                    icon: 'envelope',
                    danger: 'This email is already taken',
                    warning: 'Must include an email. Solely for login; you will not get emails from CannaFindALicense.com',
                    placeholder: 'Please enter your email',
                    onChange: handleChange
                },
                {
                    label: 'Password',
                    autoComplete: 'new-password',
                    name: 'password',
                    type: 'password',
                    eye: true,
                    icon: 'key',
                    danger: 'Must have a password!',
                    warning: <div>
                        Please ensure your password meets all criteria
                        <ul>
                            <li>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className={"fas fa-" + (testState.minLength && testState.maxLength ? 'check has-text-success' : 'ban')}></i>
                                    </span>
                                    <span>In between 8 and 100 characters</span>
                                </span>
                            </li>
                            <li>
                                <span className="icon-text">
                                    <span className="icon">
                                        <i className={"fas fa-" + (testState.number && testState.special && testState.letter ? 'check has-text-success' : 'ban')}></i>
                                    </span>
                                    <span>Must include at least one number, uppercase and lowercase letter, and  special character</span>
                                </span>
                            </li>
                        </ul>
                    </div>,
                    placeholder: 'Please enter a password',
                    onChange: handleChange
                },
                {
                    autoComplete: 'new-password',
                    label: 'Password Check',
                    name: 'passwordCheck',
                    type: 'password',
                    icon: 'key',
                    eye: true,
                    danger: 'Passwords do not match',
                    placeholder: 'Please re-enter your password',
                    onChange: handleChange
                }
            ]} />
    )
}

export default SignUpForm