import { useContext, useState } from "react"
import { prettifier } from "../utils/text"
import { UserContext } from "../context"

const UserLists = (props) => {
    const [listName, setListName] = useState({ listName: '' })
    const [listIcon, setListIcon] = useState('plus')
    const [vis, setVis] = useState(false)
    const [loading, setLoading] = useState(false)
    const context = useContext(UserContext)
    let base = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3001/'
    : 'https://cannafindalicense.herokuapp.com/';



    function isValid(str) {
        return !/[~'!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(str);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleAddList()
    }

    const handleAddList = (e) => {
        setVis({ ...vis, input: '' })
        if (listName.listName === '') {
            return
        }
        if (!isValid(listName.listName)) {
            setVis({ ...vis, input: 'warning' })
            return
        }
        let body = {
            email: context.user.email,
            listName: String(listName.listName.toLowerCase())
        }
        fetch(base + 'api/user/lists', {
            method: 'PUT',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(data => {
                if (data.user === 'Already in lists') {
                    setVis({ ...vis, input: 'danger' })
                    return
                }
                setListIcon('check is-dark')
                setTimeout(() => {
                    context.login(data.user)
                    setListName({ listName: '' })
                    setListIcon('plus')
                }, 670)
            })
    }

    const handleListDelete = (e) => {
        let target = e.target.dataset.name
        setLoading(target)
        fetch(base + `api/user/lists?email=${context.user.email}&listName=${target}`, {
            method: 'DELETE',
            headers: { 'Content-type': 'application/json' },
        }).then(res => res.json())
            .then(data => {
                setTimeout(() => {
                    context.login(data.user)
                    setLoading(false)
                }, 250)
            })
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        setListName({ ...listName, [name]: value })
    }

    const listsLister = (arr) => {
        if (!arr) { return }
        return (
            arr.map((item, i) => (
                <div className="field has-addons" key={i}>
                    <p className="control is-expanded">
                        <button
                            type='button'
                            className="button is-fullwidth is-light"
                            onClick={(e) => {
                                props.setDropDown({ ...props.dropDown, current: item });
                                props.setActive({ ...props.active, tableView: 'favs', navModal: '' });
                            }}>
                            {prettifier(item)}
                        </button>
                    </p>
                    <p className="control">
                        <button
                            type='button'
                            className={"button is-danger " + (loading === item ? 'is-loading' : '')}
                            data-name={item}
                            onClick={handleListDelete}>
                            <span className="icon is-small" data-name={item}>
                                <i className="fas fa-trash" data-name={item}></i>
                            </span>
                        </button>
                    </p>
                </div>
            )
            ))
    }

    return (
        <aside className="menu">
            <form onSubmit={handleSubmit}>
            {listsLister(context.user.lists)}
                <div className="field">
                    <div className="control is-expanded">
                        <input
                            className="input"
                            autoComplete="off"
                            type="text"
                            placeholder="Add new List"
                            name='listName'
                            value={listName.listName}
                            onChange={handleChange} />
                    </div>

                    <div className="control">
                        <button type='button' className="button is-success is-light is-fullwidth" onClick={handleAddList}>
                            <span className="icon is-small">
                                <i className={"fas fa-" + listIcon}></i>
                            </span>
                            <span>
                                Add a new list
                            </span>
                        </button>
                    </div>
                    <p className={"help is-danger " + (vis.input === 'danger' ? '' : 'is-hidden')}>Already in lists</p>
                    <p className={"help " + (vis.input === 'warning' ? '' : 'is-hidden')}>No special characters please</p>
                </div>
            </form>
        </aside>
    )
}

export default UserLists