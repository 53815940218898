export const uppercaser = (string) => string.charAt(0).toUpperCase() + string.slice(1)
export const licenseTyper = (string) => {
    let words = string.split(' - ')
    let half = words[1].split(' license')
    let final = half[0].split(' ')
    let res = final.map(item => uppercaser(item))
    return res.join(' ')
}
export const prettifier = (str) => {
    if (!str) return
    let words = str.split(' ')
    return words.map(word => uppercaser(word)).join(' ')
}

export const phoneNumberizer = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}