import React, { useState } from "react";

const UserContext = React.createContext(false)

const UserProvider = ({ children }) => {
    const themes = {
        lit: {
            light: 'is-success is-light',
            link: 'has-text-success',
            anchor: 'has-background-success',
            standard: 'is-success',
            backgroundLight: '',
            button: '',
            background: ''
        },
        chill: {
            light: 'is-success is-light',
            link: 'has-text-success',
            anchor: 'has-background-success',
            standard: 'is-success',
            button: 'is-light',
            background: 'has-background-grey-dark',
            backgroundLight: 'has-background-grey'
        }
    }
    const [state, setState] = useState({
        theme: themes.lit,
        user: {},
        loggedIn: false
    })


    const toggleTheme = (newTheme) => {
        if (newTheme === 'lit') {
            setState({
                ...state,
                theme: themes.lit
            })
        } else {
            setState({
                ...state,
                theme: themes.chill
            })
        }
    }

    const login = (userObj) => {
        setState({
            ...state,
            user: userObj,
            loggedIn: true
        })
    }

    const logout = () => {
        setState({
            ...state,
            user: {},
            loggedIn: false
        })
        window.location.reload()
    }

    const getUser = () => {
        if (state.loggedIn) {
            return state.user
        }
    }

    return (
        <UserContext.Provider value={{ ...state, toggleTheme, login, logout, getUser }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }