import { useContext } from "react"
import { UserContext } from "../context"

const Footer = (props) => {
  const context = useContext(UserContext)
  return (
    <footer id={'main-footer'} className={"footer " + context.theme.backgroundLight} style={{ paddingBottom: '3rem' }}>
      <div className="content has-text-centered">
        <div>
          <strong>CannaFindALicense</strong> by <a href="https://www.linkedin.com/in/quinn-calhoun-074b3aa1/">Quinn Calhoun</a><br />
          <span className="tag">
            <p className="buttons are-small">
              <a href='mailto::quinn.tcalhoun@gmail.com' className="button">
                <span className="icon">
                  <i className="fas fa-envelope"></i>
                </span>
              </a>
              <a href='https://twitter.com/WritePrettyCode' className="button is-info">
                <span className="icon">
                  <i className="fab fa-twitter"></i>
                </span>
              </a>
              <a href='https://github.com/QuinnCalhoun' className="button is-dark">
                <span className="icon">
                  <i className="fab fa-github"></i>
                </span>
              </a>
            </p>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer