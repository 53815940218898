import { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../context"
import { prettifier } from "../utils/text"

const SearchButton = (props) => {
    const context = useContext(UserContext)
    const [current, setCurrent] = useState('Name')
    const [active, setActive] = useState({
        dropDownActive: ''
    })
    const container = useRef(null)

    const handleDropdownClick = (e) =>
        (active.dropDownActive === '') ?
            setActive({ ...active, dropDownActive: ' is-active' }) :
            setActive({ ...active, dropDownActive: '' })

    const handleDropdownItemClick = (e) => {
        if (e.target.getAttribute('name') !== null) {
            props.setQuery({ ...props.query, searchField: e.target.getAttribute('name') })
            setCurrent(e.target.textContent)
        }
        setActive({ ...active, dropDownActive: '' })
    }

    const handleChange = (e) => {
        let { name, value } = e.target
        props.setQuery({ ...props.query, [name]: value, page: 1 })
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                container.current &&
                !container.current.contains(e.target)
            ) {
                setActive({
                    ...active,
                    dropDownActive: '',
                });
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [active])

    const itemizer = (arr) => {
        return arr.map((item, i) => (
            <a className={"dropdown-item" + (props.query.searchField === item.name ? ' is-active ' + context.theme.anchor : '')} key={i} name={item.name}>
                {item.text}
            </a>
        )
        )
    }

    return (
        <div className="field has-addons">
            <div className="control is-expanded">
                <input
                    className="input"
                    type="text"
                    placeholder={`Search `}
                    name={'searchTerm'}
                    onChange={handleChange}
                    onKeyDown={(e) => {if (e.code === 'Enter'){e.preventDefault(); e.target.blur()}}} />
            </div>
            <div className="control">
                <div className={"dropdown" + active.dropDownActive} ref={container}>
                    <div className="dropdown-trigger">
                        <button className={"button " + context.theme.standard} style={{ width: '115px' }} type='button' aria-haspopup="true" aria-controls="dropdown-menu" onClick={handleDropdownClick}>
                            <span>{prettifier(current)}</span>
                            <span className="icon is-small">
                                <i className="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu" onMouseDown={handleDropdownItemClick}>
                        <div className="dropdown-content">
                            {itemizer(props.dropDownOptions)}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SearchButton