import { prettifier } from '../utils/text';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../context';
import SearchAndFilter from './SearchAndFilter';
import UserLists from './UserLists';
import Table from './Table';

const TableContainer = (props) => {
    const context = useContext(UserContext)
    const container = useRef(null)
    const [infoDropdown, setInfoDropdown] = useState({
        active: '',
        icon: 'fa-list',
        dent: '',
        license: ''
    })

    const handleDropdownClick = (e) =>
        (props.dropDown.active === '') ?
            props.setDropDown({ ...props.dropDown, active: ' is-active' }) :
            props.setDropDown({ ...props.dropDown, active: '' })

    const handlePagination = (e) => {
        if (props.query.page === e.target.textContent) {
            return
        }
        props.setQuery({
            ...props.query,
            page: parseInt(e.target.textContent)
        })
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                container.current &&
                !container.current.contains(e.target)
            ) {
                props.setDropDown({
                    ...props.dropDown,
                    active: '',
                });
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [props.dropDown])

    useEffect(() => {
        if (props.active.modalActive === '') {
            setInfoDropdown({ ...infoDropdown, active: '' })
        }
    }, [props.active.modalActive])

    useEffect(() => {
        const getAll = async (query) => {
            let base = (process.env.NODE_ENV === 'development')
                ? 'http://localhost:3001/'
                : 'https://cannafindalicense.herokuapp.com/';

            base += 'api/licenses?'
            let keys = Object.keys(query)
            keys.forEach((key, i) => {
                if (key === 'filters') {
                    query[key].forEach((item, i) => {
                        let str = `filters=${item}&`
                        base += str
                    })
                }
                else {
                    let str = `${key}=${query[key]}`
                    base += str
                    if (i < keys.length - 1) {
                        base += '&'
                    }
                }
            })
            let result = await fetch(base, {
                method: 'GET',
                headers: { 'Content-type': 'application/json' },
            })
            let data = await result.json()
            props.setLicenses(data)
        }

        const getFavs = async (query) => {
            let base = (process.env.NODE_ENV === 'development')
                ? 'http://localhost:3001/'
                : 'https://cannafindalicense.herokuapp.com/';
            base += 'api/favs?'
            let favs = context.user.favs.filter(fav => fav.list === props.dropDown.current)
            if (favs.length === 0) {
                props.setLicenses({ licenses: [] })
                return
            }
            favs.forEach((fav, i) => {
                if (i === 0) {
                    base += `listName=${fav.list}&`
                }
                base += `favs=${fav.licenseNumber}`
                if (i < favs.length - 1) {
                    base += '&'
                }
            })
            let result = await fetch(base, {
                method: 'GET',
                headers: { 'Content-type': 'application/json' },
            })
            let data = await result.json()
            props.setLicenses(data)
        }
        if (props.active.tableView === 'all') {
            getAll(props.query)

        } else if (props.active.tableView === 'favs') {
            getFavs(props.query)
        }
    }, [props.query, props.active.tableView, context.user, props.dropDown.current])



    return (
        <div className={'box is-fluid ' + context.theme.background} style={{ borderRadius: 0 }}>
            <SearchAndFilter active={props.active} setActive={props.setActive} query={props.query} setQuery={props.setQuery} />
            <div className={'buttons has-addons is-centered mt-2 ' + (context.user.lists ? '' : 'is-hidden')}>
                <div
                    className={'button ' + (props.active.tableView === 'all' ? context.theme.standard : '')}
                    onClick={() => {
                        props.setActive({ ...props.active, tableView: 'all' })
                        props.setDropDown({ ...props.dropDown, current: '' })
                    }
                    }>
                    <span className="icon is-small"><i className={"fas fa-globe " + (props.active.tableView === 'all' ? '' : context.theme.link)} aria-hidden="true"></i></span>
                    <span>All Licenses</span>
                </div>

                <div className="field has-addons">
                    <div className='control'>
                        <div
                            className={'button ' + (props.active.tableView === 'favs' ? context.theme.standard : '')}
                            onClick={() => {
                                props.setActive({ ...props.active, tableView: 'favs' })
                                if (!context.user.lists.length > 0) {
                                    props.setDropDown({ ...props.dropDown, current: 'No lists yet!' })
                                    return
                                }
                                props.setDropDown({
                                    ...props.dropDown,
                                    current: context.user.lists[0]
                                })
                            }}>
                            <span className="icon is-small"><i className={"fas fa-heart " + (props.active.tableView === 'favs' ? '' : context.theme.link)} aria-hidden="true"></i></span>
                            <span>Favorites</span>
                        </div>
                    </div>
                    <div className={"control " + (context.user.lists ? '' : 'is-hidden')}>
                        <div className={"dropdown is-right " + props.dropDown.active} ref={container}>
                            <div className="dropdown-trigger">
                                <button className={"button " + (props.active.tableView === 'favs' ? context.theme.standard : '')} type='button' aria-haspopup="true" aria-controls="dropdown-menu" onClick={handleDropdownClick}>
                                    <span className="icon is-small">
                                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div className="dropdown-menu" role="menu" >
                                <div className="dropdown-content px-2 py-1">
                                    <div>
                                        <UserLists
                                            dropDown={props.dropDown}
                                            setDropDown={props.setDropDown}
                                            active={props.active}
                                            setActive={props.setActive}
                                            close={(str, item) => props.setDropDown({ ...props.dropDown, active: str, current: item })} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <section className="hero is-success is-light is-small">
                <div className="hero-body">
                    <p className="title">
                        {props.active.tableView === 'all' ? 'All Licenses' : prettifier(props.dropDown.current)}
                        <div class="tags has-addons">
                            <span class="tag is-success is-light">Total: </span>
                            <a class="tag">{props.licenses.total}</a>
                        </div>
                    </p>
                </div>
            </section>
            <Table
                active={props.active}
                setActive={props.setActive}
                infoDropdown={infoDropdown}
                setInfoDropdown={setInfoDropdown}
                query={props.query}
                setQuery={props.setQuery}
                licenses={props.licenses.licenses}
                dropDown={props.dropDown}
            />
            <div className={'column is-three-fifths is-offset-one-fifth ' + context.theme.background}>
                <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                    {props.licenses.pages > 1 ?
                    <>
                    <div className={"pagination-previous is-hidden-mobile " + context.theme.backgroundLight} onClick={(e) => { if (props.query.page > 1) { props.setQuery({ ...props.query, page: (props.query.page - 1) }) } }}>Previous</div>
                    <div className={"pagination-next is-hidden-mobile " + context.theme.backgroundLight} onClick={(e) => { if (props.query.page < (props.query.finalPage ? props.query.finalPage : 100)) { props.setQuery({ ...props.query, page: (props.query.page + 1) }) } }}>Next page</div>
                    
                        <ul className="pagination-list" >
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + (props.query.page === 1 ? ' is-current ' + context.theme.anchor : context.theme.backgroundLight)}
                                    aria-label={"Goto page " + 1}>1</div>
                            </li>
                            <li className='is-hidden-mobile'><span className="pagination-ellipsis">&hellip;</span>
                            </li>
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + (props.query.page === 2 ? ' is-current ' + context.theme.anchor : context.theme.backgroundLight)}
                                    aria-label={"Goto page " + (props.query.page - 1)}>{(props.query.page - 1 > 1 ? props.query.page - 1 : 2)}</div>
                            </li>
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + (props.query.page > 2 ? ' is-current ' + context.theme.anchor : context.theme.backgroundLight)}
                                    aria-label="Page 46" aria-current="page">{(props.query.page < 4 ? 3 : props.query.page)}</div>
                            </li>
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + context.theme.backgroundLight}
                                    aria-label={"Goto page " + (props.query.page + 1)}>{(props.query.page < 4) ? 4 : props.query.page >= props.licenses.pages ? props.licenses.pages : props.query.page + 1}</div>
                            </li>
                            <li className='is-hidden-mobile'><span className="pagination-ellipsis">&hellip;</span>
                            </li>
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + context.theme.backgroundLight}
                                    aria-label={"Goto page " + (props.licenses.pages ? props.licenses.pages : 'loading')}>{props.licenses.pages ? props.licenses.pages : <i className="fas fa-spinner fa-spin"></i>}</div>
                            </li>
                        </ul>
                        </> :
                        <ul className="pagination-list" >
                            <li>
                                <div
                                    onClick={handlePagination}
                                    className={"pagination-link " + (props.query.page === 1 ? ' is-current ' + context.theme.anchor : context.theme.backgroundLight)}
                                    aria-label={"Goto page " + 1}>1</div>
                            </li>
                        </ul>}

                </nav>
            </div>


        </div>
    )
}

export default TableContainer