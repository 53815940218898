import { useContext, useState } from "react"
import { UserContext } from "../context"
import Form from "./Form"

const LogInForm = (props) => {
    const context = useContext(UserContext)
    const [button, setButton] = useState({
        loading: false,
        content: 'Submit'
    })
    const [formState, setFormState] = useState({
        email: '',
        password: ''
    })
    const [formMessage, setFormMessage] = useState({
        email: '',
        password: ''
    })

    let base = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3001/'
    : 'https://cannafindalicense.herokuapp.com/';

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormState({
            ...formState,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let body = {
            email: formState.email.toLocaleLowerCase(),
            password: formState.password
        }
        fetch(base + 'api/user/login', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(data => {
                if (data.user === 'No such user by that email') {
                    setFormMessage({ ...formMessage, email: 'danger' })
                    return
                }
                if (data.user === 'Incorrect Username/Password') {
                    setFormMessage({ ...formMessage, password: 'danger' })
                    return
                }
                setButton({ content: 'Success', loading: false })
                setTimeout(() => {
                    context.login(data.user)
                    setFormState({ email: '', password: ''})
                    props.setActive({...props.active, navModal: ''})
                }, 1000)

            })
    }

    return (
        <Form 
        formState={formState}
        setFormState={setFormState}
        formMessage={formMessage}
        setFormMessage={setFormMessage}
        handleSubmit={handleSubmit}
        button={button}
        setActive={props.setActive}
        active={props.active}
        fields={[
            {
                label: 'Email',
                name: 'email',
                type: 'email',
                icon: 'envelope',
                danger: 'No such user with this email',
                placeholder: 'Email',
                onChange: handleChange
            },
            {
                label: 'Password',
                name: 'password',
                type: 'password',
                autoComplete: 'current-password',
                eye: true,
                icon: 'key',
                danger: 'Incorrect username/password combination',
                placeholder: 'Password',
                onChange: handleChange
            }
        ]} />
    )
}

export default LogInForm