import { useContext, useState } from "react"
import { UserContext } from "../context"
import { prettifier } from "../utils/text"
import UserLists from "./UserLists"

const Profile = (props) => {
    const [active, setActive] = useState('0')
    const [button, setButton] = useState({
        loading: '',
        content: 'Delete Account'
    })
    const context = useContext(UserContext)

    let base = (process.env.NODE_ENV === 'development')
        ? 'http://localhost:3001/'
        : 'https://cannafindalicense.herokuapp.com/';


    const handleClick = (e) => {
        setActive(e.target.name)
    }

    const handleUserDelete = async (e) => {
        setButton({ ...button, loading: ' is-loading' })
        let res = await fetch(base + 'api/user?email=' + context.user.email, {
            method: 'DELETE',
            headers: { "Content-type": 'appication/json' }
        })
        let data = await res.json()
        setButton({ icon: ' fa-check', loading: '', content: 'Account Deleted!' })
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const tabs = Object.values(context.user).length ?
        [
            <div>
                <ul className={"mb-2"}>
                    <h3 className="title is-6">Your stats:</h3>
                    <li>Total lists: {context.user.lists.length}</li>
                    <li>Total favs: {context.user.favs.length}</li>
                    <li>Member since: {new Date(context.user.created).toLocaleDateString()}</li>
                </ul>
                <div className='button is-fullwidth is-danger' onClick={handleUserDelete} >{button.content}</div>
            </div>,
            <UserLists 
            dropDown={props.dropDown} 
            setDropDown={props.setDropDown} 
            active={props.active} 
            setActive={props.setActive} 
            close={props.close} />

        ] :
        []

    return (
        Object.values(context.user).length ?
            <div className={"card " + context.theme.backgroundLight}>
                <div className="card-content" >
                    <p className="title is-4 mb-0">{prettifier(context.user.name)}</p>
                    <p className="subtitle is-6">{ }</p>
                    <div className="tabs is-centered is-fullwidth mb-2" >
                        <ul onClick={handleClick}>
                            <li className={active === '0' ? 'is-active' : ''}><a name='0'>You</a></li>
                            <li key={1} className={active === '1' ? 'is-active' : ''}><a name='1'>Lists</a></li>
                        </ul>
                    </div>
                    <div>{tabs[active]}</div>
                </div>
            </div> :
            <div className={"card " + context.theme.backgroundLight}>
                <div className="card-content">
                    You shouldn't be here!
                </div>
            </div>
    )
}

export default Profile