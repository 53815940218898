import { useContext, useState } from "react"
import { UserContext } from "../context"
import Profile from "./Profile"
import LogInForm from "./LogInForm"
import Modal from "./Modal"
import Settings from "./Settings"
import SignUpForm from "./SignUpForm"

const NavBar = (props) => {
    const context = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    
    const handleNavDropdownClick = () => {
        (props.active.navActive === '') ?
            props.setActive({
                ...props.active,
                navActive: 'is-active'
            }) :
            props.setActive({
                ...props.active,
                navActive: ''
            })
    }

    return (
        <nav className={"navbar " + context.theme.standard} role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className="navbar-item is-size-2 is-size-3-mobile">
                    <span className="icon-text">
                        <span className="icon is-hidden-mobile">
                            <i className="fas fa-cannabis"></i>
                        </span>
                        <span>CannaFindALicense</span>
                    </span>
                </div>
                <div role="button" className={"navbar-burger " + (props.active.navActive ? 'is-active' : '')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={handleNavDropdownClick}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>
            <div className={"navbar-menu " + props.active.navActive + ' ' + (props.isMobile ? context.theme.backgroundLight : '')}>
                <div className="navbar-end">
                    <div className={"navbar-item"}>
                        <div className="field is-grouped columns">
                            <div className="control column">
                                <Modal
                                    id={0}
                                    active={props.active}
                                    setActive={props.setActive}
                                    style={{ width: 'auto' }}
                                    trigger={
                                        <button className={"button " + context.theme.standard + (!props.isMobile ? ' is-inverted' : '') + ' ' + context.theme.button}>
                                            <span className="icon">
                                                <i className="fas fa-cogs"></i>
                                            </span>
                                        </button>
                                    }
                                    content={
                                        <Settings
                                            query={props.query}
                                            setQuery={props.setQuery}
                                            active={props.active}
                                            setActive={props.setActive} />
                                    }
                                />
                            </div>
                            <div className={"control column " + (!context.loggedIn ? 'is-hidden' : '')}>
                                <div className={"field has-addons"}>
                                    <div className="control">
                                        <Modal
                                            id={1}
                                            active={props.active}
                                            setActive={props.setActive}
                                            style={{ width: 'auto' }}
                                            trigger={
                                                <button className={"button " + context.theme.standard + (!props.isMobile ? ' is-inverted' : '') + ' ' + context.theme.button}>
                                                    <span className="icon">
                                                        <i className="fas fa-user"></i>
                                                    </span>
                                                    <span>Profile</span>
                                                </button>
                                            }
                                            content={
                                                <Profile 
                                                dropDown={props.dropDown} 
                                                setDropDown={props.setDropDown} 
                                                active={props.active} 
                                                setActive={props.setActive}  />
                                            }
                                        />
                                    </div>
                                    <div className="control">
                                        <div className={"button is-danger " + (loading ? 'is-loading' : '')} onClick={(e) => { setLoading(true); setTimeout(() => { context.logout(); setLoading(false) }, 500) }}>Log Out</div>
                                    </div>
                                </div>
                            </div>
                            <div className={"control column " + (context.loggedIn ? 'is-hidden' : '')}>
                                <div className={"field has-addons"}>
                                    <div className="control">
                                        <Modal
                                            id={2}
                                            active={props.active}
                                            setActive={props.setActive}
                                            trigger={
                                                <button className={"button " + context.theme.standard + (!props.isMobile ? ' is-inverted' : '') + ' ' + context.theme.button} >
                                                    <span className="icon is-small">
                                                        <i className="fas fa-user-plus"></i>
                                                    </span>
                                                    <span>Sign Up</span>

                                                </button>}
                                            content={
                                                <SignUpForm setActive={props.setActive} active={props.active}/>
                                            }
                                        />
                                    </div>
                                    <div className="control">
                                        <Modal
                                            id={3}
                                            active={props.active}
                                            setActive={props.setActive}
                                            trigger={
                                                <button className={"button " + context.theme.standard + (!props.isMobile ? ' is-inverted' : '') + ' ' + context.theme.button}>
                                                    <span className="icon is-small">
                                                        <i className="fas fa-sign-in-alt"></i>
                                                    </span>
                                                    <span>Sign In</span>
                                                </button>}
                                            content={
                                                <LogInForm setActive={props.setActive} active={props.active}/>
                                            }
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar