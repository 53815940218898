import './App.css'
import './mystyles.css'
import { useEffect, useState } from 'react'
import NavBar from './components/NavBar';
import TableContainer from './components/TableContainer';
import Footer from './components/Footer';
import { UserProvider } from './context';

function App() {
  const [active, setActive] = useState({
    modalActive: '',
    copyActive: '',
    accordion: '',
    navActive: '',
    tableView: 'all',
    infoDropdown: '',
    state: 'California',
    navModal: ''
  })
  const [dropDown, setDropDown] = useState({
    active: '',
  })
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const [licenses, setLicenses] = useState({
    licenses: [],
    pages: undefined
  })
  const [query, setQuery] = useState({
    filters: [],
    includeInactive: 'active',
    limit: 15,
    page: 1,
    searchField: 'businessName',
    searchTerm: '',
    sort: 'premiseCity',
    sortDirection: 1,

  })

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.screen.width < 550;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile])


  return (
    <UserProvider>
      <NavBar
        active={active}
        setActive={setActive}
        query={query}
        setQuery={setQuery}
        isMobile={isMobile}
        dropDown={dropDown}
        setDropDown={setDropDown} />
      <TableContainer
        active={active}
        setActive={setActive}
        licenses={licenses}
        setLicenses={setLicenses}
        query={query}
        setQuery={setQuery}
        isMobile={isMobile}
        dropDown={dropDown}
        setDropDown={setDropDown} />
      <Footer />
    </UserProvider>
  );
}

export default App;
