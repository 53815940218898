import { useContext } from 'react';
import { UserContext } from '../context';
import { uppercaser, licenseTyper, prettifier, phoneNumberizer } from '../utils/text';

const Table = (props) => {
    const context = useContext(UserContext)

    let base = (process.env.NODE_ENV === 'development')
        ? 'http://localhost:3001/'
        : 'https://cannafindalicense.herokuapp.com/';

    const handleLicenseCopy = (data) => {
        props.setActive({
            ...props.active,
            copyActive: data,
        })
        navigator.clipboard.writeText(data)
        setTimeout(() => {
            props.setActive({
                ...props.active,
                copyActive: ''
            })
        }, 376)
    }

    const handleSaveFav = async (e) => {
        let { name, dataset } = e.currentTarget
        props.setInfoDropdown({ ...props.infoDropdown, dent: name, license: dataset.value, icon: 'fa-spinner fa-spin' })
        let favObject = {
            email: context.user.email,
            licenseNumber: dataset.value,
            listName: name
        }
        fetch(base + 'api/user/fav', {
            method: 'PUT',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(favObject)
        }).then(response => response.json())
            .then(data => {
                if (data.user === 'Already in favs') {
                    setTimeout(() => {
                        props.setInfoDropdown({ ...props.infoDropdown, dent: name, license: dataset.value, icon: 'fa-times has-text-danger' })
                    }, 250)
                    return
                }
                setTimeout(() => {
                    props.setInfoDropdown({ ...props.infoDropdown, dent: name, license: dataset.value, icon: 'fa-check has-text-success' })
                    context.login(data.user)
                }, 269)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const headerizer = (arr) => {
        return arr.map((item, i) => {
            let hidden = item.hidden ? 'is-hidden-mobile' : ''

            return (
                <th
                    key={i}
                    className={hidden}
                    name={item.name}
                >
                    <div className="field has-addons ">
                        <div className="control is-expanded">
                            <button className="button is-static is-fullwidth ">
                                <span>{prettifier(item.text)}</span>
                            </button>
                        </div>
                        <div className="control">
                            <button
                                className="button"
                                name={item.name}
                                onClick={(e) => {
                                    let { name } = e.currentTarget
                                    props.setQuery({
                                        ...props.query,
                                        sort: name,
                                        sortDirection: (props.query.sort === name && props.query.sortDirection === 1 ? -1 : 1)
                                    })
                                }}
                            >
                                <span className="icon is-small">
                                    <i className={"fas fa-" + (props.query.sortDirection === 1 ? 'chevron-up' : 'chevron-down') + (props.query.sort === item.name ? ' has-text-success' : '')}></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </th>
            )
        })
    }
    const tablerizer = (arr) => {

        return arr.map((item) =>
            <tr key={item.licenseNumber}>
                <th>{(props.isMobile && item.businessDBA !== '' && item.businessDBA !== 'n/a' ? <div className='subtitle'>{[prettifier(item.businessDBA)]}</div> : '')}{prettifier(item.businessName)}</th>
                <th className='is-hidden-mobile'>{(item.businessDBA !== 'n/a' && item.businessDBA !== '' ? prettifier(item.businessDBA) : '')}</th>
                <td>{prettifier(item.premiseCity)}</td>
                <td className='is-hidden-mobile'>{prettifier(item.premiseCounty)}</td>
                <td className='is-hidden-mobile'>{item.premiseZip.substr(0, 5)}</td>
                <td className='is-hidden-mobile'>{item.issuedDate.substr(5, 2) + '/' + item.issuedDate.substr(8, 2) + '/' + item.issuedDate.substr(0, 4)}</td>
                <td className='is-hidden-mobile'>{licenseTyper(item.licenseType)}</td>
                <td className='is-hidden-mobile '>
                    <span className="icon-text">
                        <span>{item.licenseNumber}</span>
                        <span className={"icon " + context.theme.link}>
                            <i className={`fas ${props.active.copyActive === item.licenseNumber ? 'fas fa-check' : 'fas fa-copy'} is-clickable`} data-lic={item.licenseNumber} onClick={(e) => handleLicenseCopy(item.licenseNumber)}>
                            </i>
                        </span>
                    </span>
                </td>
                <td>
                    <div className={"modal " + (props.active.modalActive === item.licenseNumber ? 'is-active' : '')}>
                        <div className="modal-background" onClick={() => props.setActive({ ...props.active, modalActive: '' })}></div>
                        <div className="modal-content">
                            <section className={"hero is-small " + context.theme.standard} style={{ borderRadius: '6px 6px 0 0' }}>
                                <div className="hero-body">
                                    <div className="title columns is-vcentered">
                                        <div className='column'>
                                            {(item.businessDBA !== '' && item.businessDBA !== 'n/a' && item.businessDBA !== item.businessName) ? prettifier(item.businessDBA) : prettifier(item.businessName)}
                                            <aside className={'subtitle '}>{(item.businessDBA !== '' && item.businessDBA !== 'n/a' && item.businessDBA !== item.businessName) ? prettifier(item.businessName) : (item.businessDBA !== '' && item.businessDBA !== 'n/a') ? prettifier(item.businessDBA) : 'No DBA on file'}</aside>
                                        </div>

                                        <div className='column is-narrow'>
                                            <div className='tags '>
                                                <span className={"tag is-success " + (context.theme.standard === 'is-success' ? 'is-light ' : '') + (item.adultUse !== 'yes' ? 'is-hidden' : '')}>
                                                    <span>Adult Use</span>
                                                    <span className="icon">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </span>
                                                <span className={"tag is-info " + (context.theme.standard === 'is-info' ? 'is-light ' : '') + (item.medicinal !== 'yes' ? 'is-hidden' : '')}>
                                                    <span>Medicinal</span>
                                                    <span className="icon">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className='box mb-0' style={{ borderRadius: '0' }}>
                                <div className='columns'>
                                    <div className='column'>
                                        <ul>
                                            <li>
                                                Owner: {prettifier(item.businessOwner)}
                                                <ul className='ml-2'>
                                                    <li>
                                                        Phone: {phoneNumberizer(item.phone) ? phoneNumberizer(item.phone) : 'Not on file'}
                                                    </li>
                                                    <li>
                                                        Email: {uppercaser(item.email) ? uppercaser(item.email) : 'Not on file'}
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className='column'>
                                        <ul>
                                            <li>
                                                Premise Details:
                                                <ul className='ml-2 is-clickable is-underlined' id='addressDetails' onClick={() => { 
                                                    let maps = 'https://www.google.com/maps/place/' + `${item.addressLine1}, ${item.premiseCity}, ${item.premiseCounty}, CA ${item.premiseZip.substr(0, 5)}`
                                                    window.location.href = maps
                                                 }}>
                                                    <li>
                                                        {prettifier(item.addressLine1) ? prettifier(item.addressLine1) : 'No street address listed'},
                                                    </li>
                                                    <li>
                                                        {prettifier(item.premiseCounty) ? prettifier(item.premiseCounty) + ' County' : 'No county listed'},
                                                    </li>
                                                    <li>
                                                        {prettifier(item.premiseCity)}, CA {prettifier(item.premiseZip.substr(0, 5))}
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className='column'>
                                        <ul>
                                            <li>
                                                License Info:
                                                <ul className='ml-2'>
                                                    <li>
                                                        Type: {prettifier(item.licenseType.substr(11))}
                                                    </li>
                                                    <li>
                                                        Status: {uppercaser(item.licenseStatus)}
                                                    </li>
                                                    <li>

                                                        <span className="icon-text">
                                                            <span>Issued: {item.issuedDate.substr(5, 2) + '/' + item.issuedDate.substr(8, 2) + '/' + item.issuedDate.substr(0, 4)}</span>
                                                            <span className={"icon " + context.theme.link + " is-hidden-mobile"}>
                                                                <i className={`fas ${props.active.copyActive === item.issuedDate ? 'fas fa-check' : 'fas fa-copy'} is-clickable`} data-lic={item.licenseNumber} onClick={(e) => handleLicenseCopy(item.issuedDate)}>
                                                                </i>
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li>

                                                        <span className="icon-text">
                                                            <span>Expires: {item.expiryDate}</span>
                                                            <span className={"icon " + context.theme.link + " is-hidden-mobile"}>
                                                                <i className={`fas ${props.active.copyActive === item.expiryDate ? 'fas fa-check' : 'fas fa-copy'} is-clickable`} data-lic={item.licenseNumber} onClick={(e) => handleLicenseCopy(item.expiryDate)}>
                                                                </i>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className={'box '} style={{ borderRadius: '0 0 6px 6px' }} >
                                <div className="field is-grouped">
                                    <div className={"control " + (props.isMobile ? " is-expanded " : "")}>

                                        <div className={"dropdown is-up " + (props.infoDropdown.active)}>
                                            <div className="dropdown-trigger">
                                                <button
                                                    className={"button is-fullwidth " + context.theme.standard}
                                                    aria-haspopup="true"
                                                    aria-controls="dropdown-menu"
                                                    onClick={(e) => {
                                                        props.infoDropdown.active === '' ?
                                                            props.setInfoDropdown({ ...props.infoDropdown, active: 'is-active' }) :
                                                            props.setInfoDropdown({ ...props.infoDropdown, active: '', icon: 'fa-list' })
                                                    }}
                                                >
                                                    <span>Add to favorites</span>
                                                    <span className="icon is-small">
                                                        <i className="fas fa-angle-up" aria-hidden="true"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                <div className="dropdown-content">
                                                    <aside className="menu px-2 py-2">
                                                        <p className="menu-label has-background-success-light">
                                                            {context.user.favs ?
                                                                'Select a list to save to:' :
                                                                <div>Please
                                                                    <div className='tag is-clickable' onClick={(e) => {props.setActive({ ...props.active, navModal: 3, modalActive: '', navActive: 'is-active' })}}>Login</div>
                                                                    or
                                                                    <div className='tag is-clickable' onClick={(e) => {props.setActive({ ...props.active, navModal: 2, modalActive: '', navActive: 'is-active' })}}>Sign Up</div>
                                                                    to save favs</div>}
                                                        </p>
                                                        <ul className="menu-list">
                                                            {context.user.lists ?
                                                                context.user.lists.map((list, i) => (
                                                                    <li key={i}>
                                                                        <a
                                                                            onClick={handleSaveFav}
                                                                            name={list}
                                                                            data-value={item.licenseNumber}>
                                                                            {<span className="icon-text">
                                                                                <span className="icon">
                                                                                    <i className={"fas " + (props.infoDropdown.dent === list && props.infoDropdown.license === item.licenseNumber ? props.infoDropdown.icon : 'fa-list')}></i>
                                                                                </span>
                                                                                <span>{prettifier(list)}</span>
                                                                                <span className={"tag " + (props.infoDropdown.icon.includes('danger') && props.infoDropdown.dent === list && props.infoDropdown.license === item.licenseNumber ? '' : 'is-hidden')}>
                                                                                    Already saved to this list!
                                                                                </span>
                                                                            </span>
                                                                            }
                                                                        </a>
                                                                    </li>)) :
                                                                null}

                                                        </ul>

                                                    </aside>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={"control " + (props.active.tableView === 'favs' ? '' : 'is-hidden')}>
                                        <button
                                            className="button is-fullwidth is-danger"
                                            onClick={(e) => {
                                                base += 'api/user/fav'
                                                let listName = props.dropDown.current
                                                let licenseNumber = item.licenseNumber
                                                let email = context.user.email
                                                fetch(`${base}?email=${email}&listName=${listName}&licenseNumber=${licenseNumber}`, {
                                                    method: 'DELETE',
                                                    headers: { 'Content-type': 'application/json' }
                                                }).then(response => response.json())
                                                    .then(data => {
                                                        context.login(data.user)
                                                        props.setActive({ ...props.active, modalActive: '' })
                                                    })
                                            }}>
                                            Remove from list
                                        </button>
                                    </div>
                                    <div className="control">
                                        <button
                                            className="button is-fullwidth is-danger is-light"
                                            onClick={(e) => props.setActive({ ...props.active, modalActive: '' })}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <button onClick={() => props.setActive({ ...props.active, modalActive: '' })} className="modal-close is-large " aria-label="close"></button>
                    </div>
                    <button onClick={() => props.setActive({ ...props.active, modalActive: item.licenseNumber })} className={'button is-rounded is-small ' + context.theme.standard}><strong>Info</strong></button>
                </td>
            </tr>
        )
    }

    return (
        <table className={'table is-borderless is-fullwidth ' + context.theme.backgroundLight}>
            <thead>
                <tr>
                    {headerizer([
                        { name: 'businessName', text: 'name' },
                        { name: 'businessDBA', text: 'DBA', hidden: true},
                        { name: 'premiseCity', text: 'city' },
                        { name: 'premiseCounty', text: 'county', hidden: true },
                        { name: 'premiseZip', text: 'zip', hidden: true },
                        { name: 'issuedDate', text: 'issued', hidden: true },
                        { name: 'licenseType', text: 'l. type', hidden: true },
                        { name: 'licenseNumber', text: 'l. num', hidden: true },
                    ])}
                </tr>
            </thead>
            <tbody>
                {tablerizer(props.licenses)}
            </tbody>
        </table>
    )
}

export default Table