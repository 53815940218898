import SearchButton from './SearchButtonWithDropDown.js';
import { UserContext } from '../context.js';
import { useContext } from 'react';

const SearchAndFilter = (props) => {
  const context = useContext(UserContext)

  const handleCheck = (e) => {
    if (e.target.checked) {
      let filterList = props.query.filters ? props.query.filters : []
      props.setQuery({ ...props.query, filters: [...filterList, e.target.name] })
    }
    else {
      let filters = props.query.filters
      let newFilter = e.target.name
      let final = filters.filter(item => item !== newFilter)
      props.setQuery({ ...props.query, filters: final })
    }
  }

  const checkBoxinator = (arr) => {

    return (arr.map((item, i) => {
      return (
        <label
        key={i}
          className={"checkcontainer"}
          id={(context.theme.standard === 'is-success' ? 'lit' : 'chill')}>
          {item.text}
          <input type="checkbox" name={item.name} onClick={handleCheck} />
          <span className="checkmark"></span>
        </label>
      )
    }))
  }

  return (
    <form className='columns mb-0 '>
      <div className={"column is-two-thirds is-offset-2 mb-1 " + (context.theme.standard !== 'is-success' ? 'has-background-grey-dark' : 'null')}>
        <div className='columns is-centered'>
          <div className='column is-narrow'>
            <SearchButton query={props.query} setQuery={props.setQuery} dropDownOptions={[
              { name: 'businessName', text: 'Name' },
              { name: 'businessDBA', text: 'DBA' },
              { name: 'premiseCity', text: 'City' },
              { name: 'premiseCounty', text: 'County' },
              { name: 'licenseNumber', text: 'License #' },
            ]} />
          </div>
        </div>

        <div className='columns mb-0'>
          <div className='column is-one-third is-offset-one-third'>
            <div
              className={"button is-light accordion " + props.active.accordion}
              onClick={(e) => {
                (props.active.accordion === '') ?
                  props.setActive({ ...props.active, accordion: 'active' }) :
                  props.setActive({ ...props.active, accordion: '' })
              }}>
              <span className="icon-text">
                <span>Filter</span>
                <span className="icon">
                  <i className="fas fa-filter"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={'columns '}>
          <div className='column '>
            <div className={"accordion-panel " + context.theme.background} style={(props.active.accordion !== '') ? { display: 'block' } : { display: 'none' }} >
              <div className='columns'>
                <div className={'column ' + context.theme.backgroundLight}>
                  {checkBoxinator([{ text: 'Distributor', name: 'd' }, { text: 'Distributor, Transport Only', name: 'dTO' }])}
                </div>
                <div className={'column ' + context.theme.backgroundLight}>
                  {checkBoxinator([{ text: 'Retailer', name: 'r' }, { text: 'Retailer, Non-storefront', name: 'rNS' }])}
                </div>
                <div className={'column ' + context.theme.backgroundLight}>
                  {checkBoxinator([{ text: 'Event Organizer', name: 'eO' }, { text: 'Microbusiness', name: 'mB' }])}
                </div>
                <div className={'column ' + context.theme.backgroundLight}>
                  {checkBoxinator([{ text: 'Testing Lab', name: 'tL' }])}
                  <label onClick={(e) => {
                    if (!e.target.checked) {
                      props.setQuery({ ...props.query, includeInactive: 'active' })
                    }
                    else {
                      props.setQuery({ ...props.query, includeInactive: '' })
                    }
                  }} className={"checkcontainer"} id={(context.theme.standard === 'is-success' ? 'lit' : 'chill')}>Include Inactive
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default SearchAndFilter