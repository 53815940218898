import { useContext, useState } from "react"
import { UserContext } from "../context"

const Settings = (props) => {
    const [settings, setSettings] = useState({
        display: false,
    })
    const context = useContext(UserContext)

    const handleChange = (e) => {
        let {name, value} = e.target
        props.setQuery({
            ...props.query,
            [name]: value
        })
    }

    return (
        <aside className={"box menu " + context.theme.background}>
            <ul className="menu-list mb-2">
                <li>
                    <p className="menu-label">
                        Display
                    </p>
                    <div className='buttons'>
                        <button
                            className={"button is-fullwidth is-success " + (settings.display ? 'is-focused is-light' : 'is-inverted ')}
                            onClick={(e) => {
                                setSettings({ ...settings, display: !settings.display })
                                context.toggleTheme(settings.display ? 'lit' : 'chill')
                            }}>
                            <span className="icon">
                                <i className={"fas fa-" + (settings.display ? 'sun' : 'moon')}></i>
                            </span>
                            <span>{(!settings.display ? 'Chill?' : 'Lit')}</span>
                        </button>
                    </div>
                </li>
            </ul>

            <ul className=" menu-list">
                <li>
                    <p className="menu-label">
                        Defaults
                    </p>
                    <div className="field has-addons">
                        <p className="control is-expanded">
                            <a className="button is-static">
                                Licenses per page:
                            </a>
                        </p>
                        <div className="control ">
                            <div className="select is-fullwidth">
                                <select onChange={handleChange} name={'limit'} value={props.query.limit}>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                    <option>25</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* <div className="field has-addons">
                        <p className="control">
                            <a className="button is-static">
                                State:
                            </a>
                        </p>
                        <p className="control is-expanded">
                            <div className="select is-fullwidth">
                                <select defaultValue={props.active.state} onChange={(e) => props.setActive({...props.active, state: e.target.value})}>
                                    <option>California</option>
                                    <option>Oregon</option>
                                    <option>Washington</option>
                                </select>
                            </div>
                        </p>
                    </div> */}
                </li>
            </ul>
        </aside>
    )
}

export default Settings