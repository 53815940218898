const Modal = (props) => {
    return (
        <>
            <div onClick={(e) => props.setActive({...props.active, navModal: props.id})}>
                {props.trigger}
            </div>
            <div className={"modal " + (props.active.navModal === props.id ? 'is-active' : '')}>
                <div className="modal-background" onClick={(e) => props.setActive({...props.active, navModal: ''})}></div>
                <div className="modal-content" style={props.style} >
                    {props.content}
                </div>
                <button className="modal-close is-large" onClick={(e) => props.setActive({...props.active, navModal: ''})} aria-label="close"></button>
            </div>
        </>
    )
}

export default Modal